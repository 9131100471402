
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';

import ComponentCard from '../../../components/ComponentCard';
import { usersApi } from '../../../components/api/user/UsersApi';

import useTableState, {ErrorWindow} from '../../../components/useTableState';
import WebsiteSelect from '../../../components/formElements/websiteSelect';


const UsersTable = () => {
  const [
    // eslint-disable-next-line no-unused-vars
    keycloakState, modal, setModal, toggleModal, errorState, setErrorState, showError, hideError, obj, setObj, handleFieldChange, tableState, setTableState,
  ] = useTableState();
  const [websiteId, setWebsiteId] = useState("");

  const handleGetRows = () => {
      usersApi.listByWebsite(websiteId, keycloakState.token)
        .then(
          response => {
            // console.log(tableState);
            // console.log(setTableState);
            setTableState((prev) => ({ ...prev, data: response.data, status: '', loading: false }));
          }
        )
        .catch(error => setTableState((prev) => ({ ...prev, status: `Error: ${error}` })));
  };

  const handleSubmit = async (event) => {
    setTableState((prev) => ({ ...prev, loading:true }));
    event.preventDefault();
    await usersApi.save(obj, keycloakState.token);
    await handleGetRows();
    setModal(false);
  };

  useEffect(() => {
    handleGetRows();
  }, [websiteId]);

  return (
    <div>
      <BreadCrumbs />
      <ErrorWindow errorState={errorState} hideModal={hideError}/>
      <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
        <ModalHeader toggle={toggleModal.bind(null)}>Edit user</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event)} id='userForm'>
            <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
            <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
            <FormGroup>
              <p>UUID:{obj.id}</p>
            </FormGroup>
            <FormGroup>
              <Label for='login'>login</Label>
              <Input type='text' name='login' id='login' defaultValue={obj.login}  onChange={handleFieldChange}/>
            </FormGroup>
            <FormGroup>
              <Label for='name'>User name</Label>
              <Input type='text' name='name' id='name' defaultValue={obj.name}  onChange={handleFieldChange}/>
            </FormGroup>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input type='text' name='email' id='email' defaultValue={obj.email}  onChange={handleFieldChange}/>
            </FormGroup>
            <FormGroup>
              <Label for='phone'>Phone</Label>
              <Input type='text' name='phone' id='phone' defaultValue={obj.phone}  onChange={handleFieldChange}/>
            </FormGroup>
            <FormGroup>
              <Label for='mobileuid'>mobileuid</Label>
              <Input type='text' name='mobileuid' id='mobileuid' defaultValue={obj.mobileuid}  onChange={handleFieldChange}/>
            </FormGroup>
            <FormGroup>
              <Label for='timezone'>timezone</Label>
              <Input type='text' name='timezone' id='timezone' defaultValue={obj.timezone}  onChange={handleFieldChange}/>
            </FormGroup>
            <FormGroup>
              <Button color='primary' type='submit' disabled={tableState.loading}>
                Save
              </Button>
              <Button color='secondary' className='ml-1' onClick={toggleModal.bind(null)}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Action table*/}
      {/*--------------------------------------------------------------------------------*/}
      <ComponentCard title='Users'>
        <Row className="p-1">
          <Col md='2'>
            <Button color='primary' className='ml-1' onClick={() => {
              // alert(id);
              const sobj = { login: '', name: '', id: '' };
              setObj(sobj);
              setModal(true);
            }}>
              New
            </Button>
          </Col>
          <Col md='4'>
            <p>{tableState.status}</p>
          </Col>
          <Col md='6'>
            <WebsiteSelect websiteId={websiteId} setWebsiteId={setWebsiteId}/>
          </Col>
        </Row>
{/*TODO: https://www.npmjs.com/package/react-table-v6*/}
{/*        implement onFetchData=*/}
        <ReactTable
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
              width: 50,
            },
            {
              Header: 'UUID',
              accessor: 'id',
              width: 150,
              Cell: e => {
                //console.log(e) // viewIndex here
                return (<Link to={e.original.id}>{e.original.id}</Link>);
              },
            },
            {
              Header: 'Login',
              accessor: 'login',
              width: 150,
            },
            {
              Header: 'Name',
              accessor: 'name',
              width: 150,
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 150,
            },
            {
              Header: 'Website',
              accessor: 'websiteName',
              width: 150,
            },
            {
              Header: 'Actions',
              accessor: 'id',
              sortable: false,
              filterable: false,
              Cell: e => {
                //console.log(e) // viewIndex here
                return (
                  <div className="text-end">
                    <Link to={e.original.id}><Button
                      color='primary' size='sm' round='true' icon='true'>
                      <i className='fa fa-edit' />Edit
                    </Button></Link>
                    &nbsp;
                    <Button onClick={async () => {
                      await usersApi.remove(e.original.id, keycloakState.token).catch(er => showError("Cannot delete user", er));
                      handleGetRows();
                    }}
                            className='ml-1' color='danger' size='sm' round='true' icon='true' ml>
                      <i className='fa fa-times' />Delete</Button>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-striped -highlight'
          data={tableState.data}
        />
      </ComponentCard>
      {/*--------------------------------------------------------------------------------*/}
      {/* End Action table*/}
      {/*--------------------------------------------------------------------------------*/}
    </div>
  );
};

export default UsersTable;
