// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';

import ComponentCard from '../../../components/ComponentCard';
import { userAttrTypeApi } from '../../../components/api/user/UserAttrTypeApi';

import { handleLogError } from '../../../components/misc/Helpers';
import useTableState from '../../../components/useTableState';
import attrTypeData from '../AttrTypeData';
import WebsiteSelect from '../../../components/formElements/websiteSelect';

const UserAttrTypeTable = () => {
  const [
    // eslint-disable-next-line no-unused-vars
    keycloakState, modal, setModal, toggleModal, errorState, setErrorState, showError, hideError, obj, setObj, handleFieldChange, tableState, setTableState,
  ] = useTableState();
  const [websiteId, setWebsiteId] = useState("");

  const handleGetRows = async () => {
    try {
      const response = await userAttrTypeApi.list(websiteId, keycloakState.token);
      setTableState((prev) => ({ ...prev, data: response.data, status: '', loading: false }));
    } catch (error) {
      setTableState((prev) => ({ ...prev, status: `Error: ${error}` }));
      handleLogError(error);
    }
  };
  const handleTypeChange = (value) => {
    setObj((prev) => ({ ...prev, type: value.id }));
  };
  const handleSubmit = async (event) => {
    setTableState((prev) => ({ ...prev, loading: true }));
    event.preventDefault();
    await userAttrTypeApi.save(obj, keycloakState.token).catch(er => showError('Cannot update attribute type', er));
    setModal(false);
    await handleGetRows();
  };

  useEffect(() => {
    handleGetRows();
  }, [websiteId]);

  return (
    <div>
      <BreadCrumbs />
      {/*<div>{JSON.stringify(tableState)}</div>*/}
      <Modal isOpen={errorState.visible} toggle={hideError.bind(null)}>
        <ModalHeader toggle={hideError.bind(null)}>{errorState.title}</ModalHeader>
        <ModalBody>
          {errorState.text}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={hideError.bind(null)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
        <ModalHeader toggle={toggleModal.bind(null)}>Edit user attribute type</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event)} id='editForm'>
            <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
            <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
            <FormGroup>
              <p>UUID: {obj.id}</p>
            </FormGroup>
            <FormGroup>
              <Label for='type'>Type</Label>
              <Select
                name='type'
                options={attrTypeData}
                defaultValue={attrTypeData.find((o) => o.id === obj.type)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                clearable={false}
                onChange={handleTypeChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                name='single'
                className='form-check-input'
                type='checkbox'
                id='single'
                checked={obj.single}
                onChange={handleFieldChange}
              /> &nbsp;
              <Label for='single'>Single value only</Label>
            </FormGroup>

            <FormGroup>
              <Label for='code'>code</Label>
              <Input type='text' name='code' id='code' defaultValue={obj.code} onChange={handleFieldChange} />
            </FormGroup>
            <FormGroup>
              <Label for='name'>Name</Label>
              <Input type='text' name='name' id='name' defaultValue={obj.name} onChange={handleFieldChange} />
            </FormGroup>
            <FormGroup>
              <Label for='description'>Description</Label>
              <Input type='text' name='description' id='description' defaultValue={obj.description}
                     onChange={handleFieldChange} />
            </FormGroup>
            <FormGroup>
              <Button color='primary' type='submit' disabled={tableState.loading}>
                Save
              </Button>&nbsp;
              <Button color='secondary' className='ml-1' onClick={toggleModal.bind(null)}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Action table*/}
      {/*--------------------------------------------------------------------------------*/}
      <ComponentCard title='User Attribute Types'>
        <Row className="p-1">
          <Col md='4'>
            <Button color='primary' className='ml-1' onClick={() => {
              setObj({ code: '', name: '', id: '', websiteId});
              setModal(true);
            }}>
              New
            </Button>
          </Col>
          <Col md='2'>
            <p>{tableState.status}</p>
          </Col>
          <Col md='6'>
            <WebsiteSelect websiteId={websiteId} setWebsiteId={setWebsiteId}/>
          </Col>
        </Row>

        <ReactTable
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
              width: 50,
            },
            {
              Header: 'UUID',
              accessor: 'id',
              width: 150,
            },
            {
              Header: 'Code',
              accessor: 'code',
              width: 150,
            },
            {
              Header: 'Name',
              accessor: 'name',
              width: 250,
            },
            {
              Header: 'Actions',
              accessor: 'id',
              sortable: false,
              filterable: false,
              Cell: e => {
                //console.log(e) // viewIndex here
                return (
                  <div className="text-end">
                    <Button
                      color='primary'
                      size='sm'
                      round='true'
                      icon='true'
                      onClick={() => {
                        // alert(id);
                        const sobj = tableState.data.find((o) => o.id === e.original.id);
                        setObj(sobj);
                        setModal(true);
                      }}
                    >
                      <i className='fa fa-edit' />Edit
                    </Button>
                    &nbsp;
                    <Button className='ml-1' color='danger' size='sm' round='true' icon='true' ml onClick={async () => {
                      await userAttrTypeApi.remove(e.original.id, keycloakState.token).catch(er => showError('Cannot delete user attribute type', er));
                      await handleGetRows();
                    }}>
                      <i className='fa fa-times' />Delete</Button>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-striped -highlight'
          data={tableState.data}
        />
      </ComponentCard>
      {/*--------------------------------------------------------------------------------*/}
      {/* End Action table*/}
      {/*--------------------------------------------------------------------------------*/}
    </div>
  );
};

export default UserAttrTypeTable;
