// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText,
  Button, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane,
  // InputGroup,
  // InputGroupText,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // FormFeedback,
} from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactTable from 'react-table-v6';

import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import { usersApi } from '../../../components/api/user/UsersApi';
import { websiteApi } from '../../../components/api/infra/WebsitesApi';
import { rolesApi } from '../../../components/api/user/RoleApi';
import { userAttrTypeApi } from '../../../components/api/user/UserAttrTypeApi';
import { userGroupsApi } from '../../../components/api/user/UserGroupsApi';

import { handleLogError } from '../../../components/misc/Helpers';
import { convertDateToISO, convertISOToDate } from '../../../components/misc/HelpersDate';
import useTableState from '../../../components/useTableState';
// import ComponentCard from '../../../components/ComponentCard';

const UserEditForm = () => {
  const [
    // eslint-disable-next-line no-unused-vars
    keycloakState, modal, setModal, toggleModal, errorState, setErrorState, showError, hideError, obj, setObj, handleFieldChange, tableState, setTableState,
  ] = useTableState();

  const [activeTab, setActiveTab] = useState('1');

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id } = useParams();

  const [websites, setWebsites] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userAttrTypes, setUserAttrTypes] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleGetRow = async () => {
    try {
      websiteApi.list(keycloakState.token).then((value) => setWebsites(value.data));
      rolesApi.list(keycloakState.token).then((value) => setRoles(value.data));
      userGroupsApi.list(keycloakState.token).then((value) => setUserGroups(value.data));

      const response = await usersApi.get(id, keycloakState.token);
      setObj(response.data);
      userAttrTypeApi.list(response.data.websiteId, keycloakState.token).then((value) => setUserAttrTypes(value.data));
      setLoading(false);
    } catch (error) {
      handleLogError(error);
    }
  };
  useEffect(() => {
    handleGetRow();
  }, []);
  const handleFieldChangeActivated = (e) => {
    setObj((prev) => ({ ...prev, active: e.target.checked }));
  };
  const handleWebsiteChange = (value) => {
    setObj((prev) => ({ ...prev, websiteId: value.id }));
  };
  const handleRolesChange = (value) => {
    setObj((prev) => ({ ...prev, roles: value }));
  };
  const handleUserGroupsChange = (value) => {
    setObj((prev) => ({ ...prev, groups: value }));
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    await usersApi.update(id, obj, keycloakState.token).then(navigate('/admin/user/'));
  };

  // --- Attributes modal --------------------------------------------------------------------------
  const [objAttr, setObjAttr] = useState({});

  const [modalAttr, setModalAttr] = useState(false);
  const toggleModalAttr = () => {
    setModalAttr(!modalAttr);
  };
  const handleAttrFieldChange = (event) => {
    const { type, name, value, checked } = event.target;
    let updatedValue = type === 'checkbox' ? checked : value;
    if (type === 'date') updatedValue = convertISOToDate(value);
    setObjAttr((prev) => ({ ...prev, [name]: updatedValue }));
  };
  const handleAttrTypeChange = (value) => {
    setObjAttr((prev) => ({ ...prev, type: value }));
  };

  const handleSubmitAttr = async (event) => {
    event.preventDefault();
    setObj((prev) => ({
          ...prev, attributes:
            prev.attributes.some(o => {
              return o.id === objAttr.id;
            }) ?
              prev.attributes.map(
                (a) => {
                  if (a.id === objAttr.id)
                    return ({ ...a, type: objAttr.type, value: objAttr.value });
                  return a;
                }) : [...prev.attributes, objAttr],
        }
      ),
    );

    setModalAttr(false);
  };

  // ===============================================================================================

  return (
    <>
      <BreadCrumbs />
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Inner Div*/}
      {/*--------------------------------------------------------------------------------*/}
      {objAttr.id !== undefined && (
        <Modal isOpen={modalAttr} toggle={toggleModalAttr.bind(null)}>
          <ModalHeader toggle={toggleModalAttr.bind(null)}>Edit attribute</ModalHeader>
          <ModalBody>
            <Form onSubmit={(event) => handleSubmitAttr(event)} id='editProp'>
              <Input type='hidden' name='id' id='id' defaultValue={objAttr.id} />
              <FormGroup>
                <Label for='login'>Type:</Label>
                {userAttrTypes !== undefined && (<Select
                  name='attrTypes'
                  onChange={handleAttrTypeChange}
                  options={userAttrTypes.filter(o=> !obj.attributes.some(oa => (oa.type.id === o.id && oa.type.single === true) ))}
                  defaultValue={userAttrTypes.find((o) => o.id === objAttr.type.id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  clearable={false}
                />)}
              </FormGroup>
              <FormGroup>
                <Label for='shortValue'>Short value</Label>
                <Input type='text' name='shortValue' id='shortValue' defaultValue={objAttr.shortValue}
                       onChange={handleAttrFieldChange} />
              </FormGroup>
              <FormGroup>
                <Label for='value'>Value</Label>
                <Input type='text' name='value' id='value' defaultValue={objAttr.value}
                       onChange={handleAttrFieldChange} />
              </FormGroup>
              <FormGroup>
                <Button color='primary' type='submit'>
                  Save
                </Button>&nbsp;
                <Button color='secondary' className='ml-1' onClick={toggleModalAttr.bind(null)}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      )}
      <Form onSubmit={(event) => handleSubmit(event)} id='userForm' disabled={loading}>
        <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
        <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
        <Row>
          {/*--------------------------------------------------------------------------------*/}
          {/* Highlighted Header Form                                                        */}
          {/*--------------------------------------------------------------------------------*/}
          <Col md='12'>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === '1' ? 'active' : ''}
                    onClick={() => {
                      toggleActiveTab('1');
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === '2' ? 'active' : ''}
                    onClick={() => {
                      toggleActiveTab('2');
                    }}
                  >
                    Attributes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === '3' ? 'active' : ''}
                    onClick={() => {
                      toggleActiveTab('3');
                    }}
                  >
                    Security
                  </NavLink>
                </NavItem>
              </Nav>
              <CardTitle tag='h4' className='border-bottom bg-primary p-3 mb-0 text-white'>
                Edit user: {obj.name}
              </CardTitle>
              {/*<div>{JSON.stringify(obj)}</div>*/}
              <TabContent className='p-4' activeTab={activeTab}>
                <TabPane tabId='1'>

                  <CardBody>

                    <Row>
                      <Col>
                        <Row>
                          <Col md='4' className='text-end'>
                            Active:
                          </Col>
                          <Col md='8'>
                            <Input
                              name='activated'
                              className='form-check-input'
                              type='checkbox'
                              id='activated'
                              checked={obj.active}
                              onChange={handleFieldChangeActivated}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            Id:
                          </Col>
                          <Col md='8'>{obj.id}</Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            UUID:
                          </Col>
                          <Col md='8'>
                            {obj.id}
                          </Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            Website:
                          </Col>
                          <Col md='8'>
                            {websites !== undefined && obj.id !== undefined && (<Select
                              name='website'
                              onChange={handleWebsiteChange}
                              options={websites} // here
                              // defaultValue={{ name: "Monstasks", id: "cfe5fc05-44ad-4a9d-9219-9196d513974b" }}
                              defaultValue={websites.find((o) => o.id === obj.websiteId)}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              clearable={false}
                            />)}
                          </Col>
                        </Row>

                      </Col>
                      <Col>
                        <Row>
                          <Col md='4' className='text-end'>
                            Created:
                          </Col>
                          <Col md='8'>
                            {obj.dateCreated}
                          </Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            Modified:
                          </Col>
                          <Col md='8'>
                            <span>{obj.dateModified}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md='4' className='text-end'>
                            Deleted:
                          </Col>
                          <Col md='8'>
                            {obj.dateDeleted}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>

                  <CardBody className='bg-light'>
                    <CardTitle tag='h4' className='mb-0'>
                      Person Info
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Name</Label>
                          <Input type='text' name='name' placeholder='Name' defaultValue={obj.name}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Login</Label>
                          <Input type='text' name='login' placeholder='Login' defaultValue={obj.login}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Date of Birth</Label>
                          <Input type='date' placeholder='DOB Here' name='birthday'
                                 defaultValue={convertDateToISO(obj.birthday)} onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Lang:</Label>
                          <Input type='text' placeholder='' name='lang' value={obj.lang} onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className='bg-light'>
                    <CardTitle tag='h4' className='mb-0'>
                      Contacts
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input type='text' name='email' placeholder='Email' defaultValue={obj.email}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Contact email</Label>
                          <Input type='text' name='contactEmail' placeholder='Email' defaultValue={obj.contactEmail}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Phone</Label>
                          <Input type='text' name='phone' placeholder='Phone' defaultValue={obj.phone}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className='bg-light'>
                    <CardTitle tag='h4' className='mb-0'>
                      Address
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Country</Label>
                          <Input type='text' placeholder='' name='country' value={obj.country}
                                 onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label>City</Label>
                          <Input type='text' placeholder='' name='city' value={obj.city} onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                      <Col md='12'>
                        <FormGroup>
                          <Label>Address</Label>
                          <Input type='text' name='address' defaultValue={obj.address} onChange={handleFieldChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </TabPane>
                <TabPane tabId='2'>
                  <CardBody>
                    <Row>
                      <Row>
                        <Col md='12'>
                          <Button color='primary' className='ml-1' onClick={() => {
                            let minId = -1;
                            if (obj.attributes != null && obj.attributes.length > 0) {
                              const minAttr = obj.attributes.reduce(
                                (a, b) => {
                                  return a.id > b.id ? b : a;
                                },
                              );
                              if (minAttr == null || minAttr.id >= 0) minId = -1;
                              else minId -= minAttr.id;
                            }
                            setObjAttr({ id: minId, type: { code: '', name: '', id: '' }, value: '' });
                            setModalAttr(true);
                          }}>
                            New
                          </Button>
                        </Col>
                      </Row>
                      <ReactTable
                        columns={[
                          {
                            Header: 'Code',
                            accessor: 'type.code',
                            width: 150,
                          },
                          {
                            Header: 'Name',
                            accessor: 'type.name',
                            width: 250,
                          },
                          {
                            Header: 'Value',
                            accessor: 'value',
                            width: 250,
                          },
                          {
                            Header: 'Actions',
                            accessor: 'id',
                            sortable: false,
                            filterable: false,
                            Cell: e => {
                              //console.log(e) // viewIndex here
                              return (
                                <div className="text-end">
                                  <Button color='primary' size='sm' round='true' icon='true'
                                    onClick={async () => {
                                      // alert(id);
                                      const sobj = obj.attributes.find((o) => o.id === e.original.id);
                                      setObjAttr(sobj);
                                      setModalAttr(true);
                                    }}>
                                    <i className='fa fa-edit' />Edit
                                  </Button>
                                  &nbsp;
                                  <Button className='ml-1' color='danger' size='sm' round='true' icon='true' ml
                                          onClick={async () => {
                                            // await rolesApi.remove(e.original.id, keycloakState.token).catch(er => showError('Cannot delete Role', er));
                                            // await handleGetRows();
                                            // console.log(`delete ${e.original.id}`);
                                            setObj((prev) => ({
                                                  ...prev, attributes:
                                                      prev.attributes.filter(
                                                        (a) => (a.id !== e.original.id))
                                                })
                                            );
                                          }}>
                                    <i className='fa fa-times' />Delete</Button>
                                </div>
                              );
                            },
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationBottom
                        className='-striped -highlight'
                        data={obj.attributes}
                      />
                    </Row>
                  </CardBody>
                </TabPane>
                <TabPane tabId='3'>
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Groups</Label>
                          {userGroups !== undefined && obj !== undefined && obj.groups !== undefined && (<Select
                            name='groups'
                            isMulti
                            onChange={handleUserGroupsChange}
                            options={userGroups} // here
                            defaultValue={userGroups.filter((o) => (obj.groups !== undefined && obj.groups.some(r => r.id === o.id)))}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            clearable={false}
                          />)}
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label>Roles</Label>
                          {roles !== undefined && obj !== undefined && obj.roles !== undefined && (<Select
                            name='roles'
                            isMulti
                            onChange={handleRolesChange}
                            options={roles} // here
                            // defaultValue={{ name: "Monstasks", id: "cfe5fc05-44ad-4a9d-9219-9196d513974b" }}
                            defaultValue={roles.filter((o) => (obj.roles !== undefined && obj.roles.some(r => r.id === o.id)))}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            clearable={false}
                          />)}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </TabPane>
              </TabContent>
              <CardBody className='border-top gap-2 d-flex align-items-center'>
                <Button type='submit' className='btn btn-success' disabled={loading}>
                  Save
                </Button>
                <Link to='/admin/user'>
                  <Button type='button' className='btn btn-dark ml-2'>
                    Cancel
                  </Button>
                </Link>
              </CardBody>

            </Card>
          </Col>
        </Row>
      </Form>
      {/*--------------------------------------------------------------------------------*/
      }
      {/*End Inner Div*/
      }
      {/*--------------------------------------------------------------------------------*/
      }
    </>
  )
}

export default UserEditForm;
