import axios from 'axios'
import {config} from '../../../Constants'

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error.response.status === 404) {
    return { status: error.response.status };
  }
  return Promise.reject(error.response);
});

// -- Helper functions

function bearerAuth(token) {
  return `Bearer ${token}`
}
function listByWebsite(websiteId, token) {
  return instance.get('/api/cms/msggroup/list/', {
    params: {
      websiteId
    },
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function get(id, token) {
  return instance.get(`/api/cms/msggroup/${id}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function create(obj, token) {
  return instance.post('/api/cms/msggroup', obj, {
    headers: {'Content-Type': 'application/json', 'Authorization': bearerAuth(token) }
  })
}

function update(id, obj, token) {
  return instance.put(`/api/cms/msggroup/${id}`, obj, {
    headers: {'Content-Type': 'application/json', 'Authorization': bearerAuth(token) }
  })
}

function save(obj, token) {
  const {id} = obj;
  if (id.length > 0)
    return update(id,obj, token);
  return create(obj, token);
}


function remove(id, token) {
  return instance.delete(`/api/cms/msggroup/${id}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

// eslint-disable-next-line import/prefer-default-export
export const msgGroupApi = {
  listByWebsite,
  get,
  create,
  update,
  save,
  remove
}

// -- Axios
