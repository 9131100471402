import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useTableState from '../useTableState';
import { websiteApi } from '../api/infra/WebsitesApi';


const WebsiteSelect = ({ websiteId, setWebsiteId, handleWebsiteCallback }) => {
  const [keycloakState] = useTableState();
  const [websites, setWebsites] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState('');
  const handleWebsiteChange = (value) => {
    setWebsiteId(value.id);
    setSelectedWebsite(value);
    if (handleWebsiteCallback !== undefined) handleWebsiteCallback(value);
  };
  useEffect(() => {
    websiteApi.list(keycloakState.token).then((value) => {
      setWebsites(value.data);
      if (websiteId === undefined || websiteId === '')
        if (value.data.length > 0) {
          setSelectedWebsite(value.data[0]);
          setWebsiteId(value.data[0].id);
        }
    });
  }, []);

  return (
    <Select
      name='website'
      onChange={handleWebsiteChange}
      options={websites} // here
      value={selectedWebsite}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      clearable={false}
    />
  );
};
WebsiteSelect.propTypes = {
  websiteId: PropTypes.string,
  setWebsiteId: PropTypes.func,
  handleWebsiteCallback: PropTypes.func
};
export default WebsiteSelect;