import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';

import ComponentCard from '../../../components/ComponentCard';
import { rolesApi } from '../../../components/api/user/RoleApi';

import { handleLogError } from '../../../components/misc/Helpers';
import { websiteApi } from '../../../components/api/infra/WebsitesApi';
import useTableState from '../../../components/useTableState';
import WebsiteSelect from '../../../components/formElements/websiteSelect';


const RolesTable = () => {
  const [
    // eslint-disable-next-line no-unused-vars
    keycloakState, modal, setModal, toggleModal, errorState, setErrorState, showError, hideError, obj, setObj, handleFieldChange, tableState, setTableState,
  ] = useTableState();
  const [websites, setWebsites] = useState([]);  //edit form
  const [websiteId, setWebsiteId] = useState("");

  const handleGetRows = async () => {
    try {
      const response = await rolesApi.listByWebsite(websiteId, keycloakState.token);
      // eslint-disable-next-line no-use-before-define
      setTableState((prev) => ({...prev, data : response.data, status : "", loading: false }));
      if (websites.length === 0) {
        const websitesResponse = await websiteApi.list(keycloakState.token);
        await setWebsites(websitesResponse.data);
      }
    } catch (error) {
      setTableState((prev) => ({...prev, status : `Error: ${error}` }));
      handleLogError(error);
    }
  }
  const handleWebsiteChange = (value) => {
    setObj((prev) => ({...prev, websiteId: value.id}));
  };

  const handleSubmit = async (event) => {
    setTableState((prev) => ({...prev, loading: true}));
    event.preventDefault();
    await rolesApi.save(obj, keycloakState.token).catch(er => showError("Cannot update role", er));
    setModal(false);
    await handleGetRows();
  };

  useEffect(() => {
    handleGetRows();
  }, [websiteId]);

  return (
    <div>
      <BreadCrumbs />
      {/*<div>{JSON.stringify(tableState)}</div>*/}
      <Modal isOpen={errorState.visible} toggle={hideError.bind(null)}>
        <ModalHeader toggle={hideError.bind(null)}>{errorState.title}</ModalHeader>
        <ModalBody>
          {errorState.text}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={hideError.bind(null)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
        <ModalHeader toggle={toggleModal.bind(null)}>Edit role</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event)} id="editForm">
            <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
            <Input type='hidden' name='id' id='id' defaultValue={obj.id} />
            <FormGroup>
              <p>UUID: {obj.id}</p>
            </FormGroup>
            <FormGroup>
              <Label for='login'>Website:</Label>
                {websites !== undefined && (<Select
                  name="website"
                  onChange={handleWebsiteChange}
                  options={websites} // here
                  defaultValue={websites.find((o) => o.id === obj.websiteId)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  clearable={false}
                />)}
            </FormGroup>
            <FormGroup>
              <Label for='login'>code</Label>
              <Input type='text' name='code' id='code' defaultValue={obj.code}  onChange={handleFieldChange} />
            </FormGroup>
            <FormGroup>
              <Label for='name'>Name</Label>
              <Input type='text' name='name' id='name' defaultValue={obj.name} onChange={handleFieldChange} />
            </FormGroup>
            <FormGroup>
              <Button color='primary' type='submit' disabled={tableState.loading}>
                Save
              </Button>&nbsp;
              <Button color='secondary' className='ml-1' onClick={toggleModal.bind(null)}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Action table*/}
      {/*--------------------------------------------------------------------------------*/}
      <ComponentCard title='Roles'>
        <Row className="p-1">
          <Col md='2'>
            <Button color='primary' className='ml-1' onClick={() => {
              setObj({ code: '', name: '', id: '', websiteId });
              setModal(true);
            }}>
              New
            </Button>
          </Col>
          <Col md='4'>
            <p>{tableState.status}</p>
          </Col>
          <Col md='6'>
            <WebsiteSelect websiteId={websiteId} setWebsiteId={setWebsiteId}/>
          </Col>
        </Row>

        <ReactTable
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
              width: 50,
            },
            {
              Header: 'UUID',
              accessor: 'id',
              width: 150,
              // Cell: e => {
              //   console.log(e) // viewIndex here
              //   return (<Link to={e.original.id}>{e.original.id}</Link>)
              // }
              // render:  ({ row })  => (<Link to={row.id}>{row.id}</Link>)
            },
            {
              Header: 'Code',
              accessor: 'code',
              width: 150,
            },
            {
              Header: 'Name',
              accessor: 'name',
              width: 250,
            },
            {
              Header: 'Website',
              accessor: 'websiteName',
              width: 150,
            },
            {
              Header: 'Actions',
              accessor: 'id',
              sortable: false,
              filterable: false,
              Cell: e => {
                //console.log(e) // viewIndex here
                return (
                  <div className="text-end">
                    <Button
                      color='primary'
                      size='sm'
                      round='true'
                      icon='true'
                      onClick={() => {
                        // alert(id);
                        const sobj = tableState.data.find((o) => o.id === e.original.id);
                        setObj(sobj);
                        setModal(true);
                      }}
                    >
                      <i className='fa fa-edit' />Edit
                    </Button>
                    &nbsp;
                    <Button className='ml-1' color='danger' size='sm' round='true' icon='true' ml onClick={async () => {
                      await rolesApi.remove(e.original.id, keycloakState.token).catch(er => showError('Cannot delete Role', er));
                      await handleGetRows();
                    }}>
                      <i className='fa fa-times' />Delete</Button>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-striped -highlight'
          data={tableState.data}
        />
      </ComponentCard>
      {/*--------------------------------------------------------------------------------*/}
      {/* End Action table*/}
      {/*--------------------------------------------------------------------------------*/}
    </div>
  );
};

export default RolesTable;
